const theme = {
  colors: {
    base: '#2E2E38',
    secondary: '#fff',
    grey: '#efeff3',
    tertiary: '#CCEAFF',
    highlight: '#456FF7',
  },
  sizes: {
    maxWidth: '1090px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}

export default theme
