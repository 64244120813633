import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import logoImg from '../images/logo_full.png'
import LanguageSelector from './LanguageSelector';

const Header = styled.header`
  background: ${props => props.theme.colors.grey};
  width: 100%;
  padding: 1.5em 0;
`
const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;

  ul {
    display: flex;
    justify-content: space-between;
  }

  li {
    display: flex;
    align-items: center;
    margin-left: 1em;
    &:first-child {
      position: relative;
      margin: 0;
      flex-basis: 100%;
    }
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.base};
    font-weight: 600;
    transition: all 0.2s;
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
`

const Image = styled.img`
  width: 40px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
`

const activeLinkStyle = {
  color: 'darkgrey',
}

const Menu = ({ locale, langs }) => {
  return (
    <Header>
      <Nav>
        <ul>
          <li>
            <Link to={`/${locale}/`}>
              <Image src={logoImg} />
              Optimus Consult
            </Link>
          </li>
          <li>
            <Link to={`/${locale}/`} activeStyle={activeLinkStyle}>
              Mission
            </Link>
          </li>
          <li>
            <Link to={`/${locale}/services/`} activeStyle={activeLinkStyle}>
              Services
            </Link>
          </li>
          <li>
            <Link to={`/${locale}/optiflow/`} activeStyle={activeLinkStyle}>
              Optiflow
            </Link>
          </li>
          <li>
            <Link to={`/${locale}/optismed/`} activeStyle={activeLinkStyle}>
              Optismed
            </Link>
          </li>
          <li>
            <Link to={`/${locale}/blog/`} activeStyle={activeLinkStyle}>
              Blog
            </Link>
          </li>
          <li>
            <Link to={`/${locale}/contact/`} activeStyle={activeLinkStyle}>
              Contact
            </Link>
          </li>
          <li>
            <LanguageSelector langs={langs} locale={locale} />
          </li>
        </ul>
      </Nav>
    </Header>
  )
}

export default Menu
