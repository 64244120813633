import React from 'react'
import { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { IntlProvider, addLocaleData } from 'react-intl';
import favicon from '../images/favicon.ico'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import config from '../utils/siteConfig'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import 'intl';
import languages from '../data/languages';

import en from 'react-intl/locale-data/en';
import 'intl/locale-data/jsonp/en';
import fr from 'react-intl/locale-data/fr';
import 'intl/locale-data/jsonp/fr';

addLocaleData([...en, ...fr]);

const Template = ({ children }) => {
  let url = '/';
  if (typeof window !== 'undefined') {
    url = window.location.pathname;
  }
  const { langs, defaultLangKey } = languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);
  const homeLink = `/${langKey}/`;
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url));

  const i18nMessages = require(`../data/messages/${langKey}`);

  return (
    <IntlProvider locale={langKey === 'en-US' ? 'en' : 'fr'} messages={i18nMessages}>
      <div className="siteRoot">
        <Helmet>
          <title>{config.siteTitle}</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href={favicon} />
          <html lang={langKey} />
        </Helmet>

        <ThemeProvider theme={theme}>
          <>
            <div className="siteContent">
              <Menu locale={langKey} langs={langsMenu} />
              {children}
            </div>
            <Footer />
          </>
        </ThemeProvider>
        <GlobalStyle />
      </div>
    </IntlProvider>
  )
}

export default Template
