import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

const LanguageSelector = (props) => {
  const links = props.langs.map(lang =>
    <li style={{ display: 'inline-block' }} key={lang.langKey}>
      <Link to={lang.link} key={lang.langKey} style={{ color: lang.selected && 'darkgrey' }}>
        {lang.langKey === 'en-US' ? 'EN' : 'FR'}
      </Link>
    </li>
  );

  return (
    <ul>
      {links}
    </ul>
  );
};

LanguageSelector.propTypes = {
  langs: PropTypes.array
};

export default LanguageSelector;